#hibiscus-subscription-form {
    .form-group {
        position: relative;
        margin-bottom: 20px;
    }

    .field-msg {
        display: none;
        position: relative;
        left: 2px;
        bottom: 0;
        margin-top: 10px;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05em;

        &.show {
            display: inline-block;
        }
    }

    .error {
        //display: inline-block;
        padding: 1em;
        border: 2px solid #e8b036;
        color: #e8b036;
    }

    .success {
        //display: block;
        padding: 1em;
        border: 2px solid rgba($color: #ffffff, $alpha: 0.6);
        color: rgba($color: #ffffff, $alpha: 0.6);
    }

}

.material-checkbox {
    --color: #e8b036;
    padding-left: 35px;
    padding-right: 10px;

    .material-control-input {
        display: none;

        &:checked~.material-control-indicator {
            border-color: var(--color);
            transform: rotateZ(45deg) translate(1px, -5px);
            width: 10px;
            border-top: 0px solid #fff;
            border-left: 0px solid #fff;
        }
    }

    .material-control-indicator {
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
        height: 25px;
        width: 25px;
        border: 2px solid #e8b036;
        transition: .3s;
    }
}

.btn-subscribe {
    background: #28a745;
    border: #28a745 1px solid;
    color: #fff;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 40px;
    transition: all ease .2s;

    &:hover {
        background: darken(#28a745, 5%);
        border-color: darken(#28a745, 5%);
        color: #fff;
        text-decoration: none;
    }
}